import { navigate } from 'gatsby';
import React, { useState, useEffect, useContext } from 'react';
import SpecificProjects from '../../components/cms-modules/dynamicTeaser/components/specificProjects';
import CompanyDetail from '../../components/company-detail/detail';
import ContributionDetail from '../../components/contributions/detail';
import Headline from '../../components/ui/headline';
import Loading from '../../components/ui/loading';
import { useLocale } from '../../context/locale.context';
import { MicrocopyContext } from '../../context/microcopy.context';
import { getCopy, withMicrocopy } from '../../helpers/copy';
import { getAPIRoot } from '../../helpers/fetch';
import Container from '../../modules/container';
import Layout from '../../modules/layout';
import * as styles from './contribution.module.scss';

const PreviewContributionDetail = () => {
  const { locale } = useLocale();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const url = new URL(window.location);
    const getContributionDetail = async () => {
      let apiRoot = await getAPIRoot();

      setLoading(true);
      fetch(
        `${apiRoot}${
          process.env.GATSBY_API_PROJECT_DETAIL
        }/${url.searchParams.get('entryId')}`
      )
        .then((res) => res.json())
        .then((res) => {
          setLoading(false);
          setData(res);
        })
        .catch((e) => {
          navigate('/404');
        });
    };

    getContributionDetail();
  }, []);

  const relatedProjects = data?.relatedEntries.map((item) =>
    item.id.toString()
  );

  const TranslatedContent = withMicrocopy(() => {
    const microcopy = useContext(MicrocopyContext);
    const headline = getCopy('html.relatedprojects', microcopy);

    if (!data || loading) {
      return (
        <Layout
          title={'title.loadingPage'}
          description={'desc.loadingPage'}
          dynamicPagePreview
          preview
        >
          <Loading />
        </Layout>
      );
    }

    return (
      <Layout
        title={data.name}
        description={data.description}
        preview
        dynamicPagePreview
      >
        <div>
          <Container fluid>
            <ContributionDetail data={data} />
          </Container>
          <div>
            <Container fluid>
              <Headline
                text={getCopy('html.relatedprojects', microcopy)}
                level="h3"
                type="h1"
              />
              {relatedProjects && (
                <SpecificProjects
                  headline={headline}
                  dataIds={relatedProjects}
                />
              )}
            </Container>
          </div>
        </div>
      </Layout>
    );
  }, locale);

  return <TranslatedContent />;
};

export default PreviewContributionDetail;
